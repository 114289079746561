<template>
  <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
    <template v-slot:title>
      Hubungi Kami
    </template>
    <template v-slot:body>
      <b-row>
        <b-col sm="6">
          <div class="row">
            <div class="col-md-12">
              <span class="text">
                <h5 class="text pb-3">
                  <p align="left">
                    Komisi Nasional Hak Asasi Manusia<br />Republik Indonesia
                    <br />
                    Jl. Latuharhary No. 4B, Menteng, Jakarta Pusat, <br />DKI
                    Jakarta 10310<br />
                    Tlp : 021-3925230<br />
                    Fax : 021-3925227 <br>
                    WA : 081226798880<br />
                    email : dataaduan@komnasham.go.id
                  </p>
                </h5>
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="6">
          <span class="text-muted">
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15865.793333841546!2d106.835487!3d-6.204439!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5edf3014f90f9bcc!2sKOMNAS+HAM!5e0!3m2!1sen!2sid!4v1478681969477"
                height="auto"
                frameborder="0"
                style="border:0;margin:auto;"
                allowfullscreen=""
              >
              </iframe>
            </div>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <div class="row">
            <div style="float:left;">
              <span class="text-muted">
                <img
                  src="media/image/komnas_wa.png"
                  style="height:120;width:240px"
                />
              </span>
            </div>
            <div style="float:left;margin-left:40px;margin-top:10px;">
              <a
                href="https://wa.me/message/OMVTY6INXLWIE1"
                target="_blank"
                class="text-primary text-hover-primary"
              >
                <span class="text">
                  <img
                    src="media/image/tinggalkanpesan.png"
                    style="width:120px"
                  />
                  <br />Tinggalkan Pesan
                </span>
              </a>
            </div>
            <div style="float:left;margin-left:40px;margin-top:10px;">
              <a
                href="http://pengaduan.komnasham.go.id/id/"
                target="_blank"
                class="text-primary text-hover-primary"
              >
                <span class="text-muted">
                  <img
                    src="media/image/pengaduanonline.png"
                    style="width:240px"
                  />
                </span>
              </a>
            </div>
            <div style="float:left;margin-left:40px;margin-top:10px;">
              <a
                href="https://www.komnasham.go.id/ppid/"
                target="_blank"
                class="text-primary text-hover-primary"
              >
                <span class="text-muted">
                  <img src="media/image/ppid.png" style="width:240px" />
                </span>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <span class="text-muted">
            <img
              src="media/image/contact.svg"
              style="margin:auto;max-width:100%;"
            />
          </span>
        </b-col>
      </b-row>
    </template>
  </KTCard>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "ContactUs",
  data() {
    return {};
  },
  components: {
    KTCard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.CONTACT_US"), route: "contact-us" }
    ]);
  },
  computed: mapGetters([]),
  methods: {},
  search() {}
};
</script>

<style scoped></style>
